import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "./shared/Link";
import styled from "styled-components";
import Button from "./shared/Button";
import Logo from "./shared/Logo";
import ButtonGroup from "./shared/ButtonGroup";
import { GRAY } from "../utils/colors";

const StyledNavbarStandard = styled.nav`
  background-color: #0c3c9b;
  padding: 4px;
`;

const StyledNavbarLesson = styled(StyledNavbarStandard)`
  background-color: #eff1f4;
`;

const StyledNavbarHome = styled(StyledNavbarStandard)`
  background-color: transparent;
  margin-top: 120px;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const NavbarContentsStandard = styled.div`
  display: grid;
  grid-template-columns: 200px 105px auto 240px;
  max-width: 100%;
  margin: auto;
  align-items: center;
  padding: 0px 10px;

  @media (max-width: 600px) {
    grid-template-columns: 130px 100px 1fr 1fr;
  }
`;

const NavbarContentsLesson = styled(NavbarContentsStandard)`
  max-width: 100%;
`;

const NavbarContentsHome = styled(NavbarContentsStandard)`
  max-width: 1300px;
  grid-template-columns: 200px 200px auto 240px;

  @media (max-width: 600px) {
    grid-template-columns: 130px 100px 1fr 1fr;
  }
`;

const NavbarConfig = {
  standard: StyledNavbarStandard,
  lesson: StyledNavbarLesson,
  home: StyledNavbarHome,
};

const pathColorConfig = {
  standard: "white",
  lesson: "black",
  home: GRAY,
};

const NavbarContentsConfig = {
  standard: NavbarContentsStandard,
  lesson: NavbarContentsLesson,
  home: NavbarContentsHome,
};

const LessonsLink = styled(Link)`
  @media (max-width: 600px) {
    margin-left: 10px !important;
    font-size: 13px !important;
  }
`;

const NavBar = ({ location, theme }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  let StyledNavbar = NavbarConfig[theme];
  const NavbarContents = NavbarContentsConfig[theme];

  function logout() {
    const url = window.location.origin.includes("localhost")
      ? "http://localhost:3000"
      : "https://api.react.school";

    fetch(`${url}/users/sign_out`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        }
        return response;
      })
      .catch(() => console.log("An error occurred while adding the todo item"));
  }

  return (
    <div>
      <StyledNavbar>
        <div>
          <NavbarContents>
            <div>
              <Link to={"/"}>
                <Logo variant={theme} />
              </Link>
            </div>
            <div>
              <LessonsLink
                style={{
                  color: pathColorConfig[theme],
                  marginLeft: theme == "home" ? "60px" : "16px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                to="/curriculum"
              >
                Lessons >
              </LessonsLink>
            </div>
            <div />
            {user ? (
              <ButtonGroup>
                {" "}
                <Button secondary onClick={logout}>
                  <span> Log out</span>
                </Button>
                {/*<Link to="/profile">*/}
                {/*  {" "}*/}
                {/*  <Button>Profile</Button>{" "}*/}
                {/*</Link>{" "}*/}
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                {" "}
                <Link to="/login">
                  <Button secondary> Login</Button>{" "}
                </Link>
                <Link to="/join">
                  <Button> Sign up </Button>
                </Link>
              </ButtonGroup>
            )}
          </NavbarContents>
        </div>
      </StyledNavbar>
    </div>
  );
};

export default NavBar;
