import React from "react";
import styled from "styled-components";
// NAVBAR ONLY
const ButtonGroup = styled.div`
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  justify-self: end;

  @media (max-width: 600px) {
    button {
      font-size: 12px;
      min-width: 60px !important;
      gap: 8px;
    }
  }
`;

export default ButtonGroup;
