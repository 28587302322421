import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import NavBar from "./NavBar";
import "./layout.css";
import "./custom-layout.css";

const LayoutWrapper = styled.div`
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth}px;

  @media (max-width: 1300px) {
    margin: 16px;
  }
`;

const Layout = ({
  children,
  maxWidth = 960,
  theme = "standard",
  otherChildren = [],
}) => {
  return (
    <>
      <NavBar theme={theme} />
      {otherChildren}
      <LayoutWrapper maxWidth={maxWidth}>
        <main>{children}</main>
        {/*<footer*/}
        {/*  style={{*/}
        {/*    marginTop: `2rem`,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  © {new Date().getFullYear()} React School*/}
        {/*</footer>*/}
      </LayoutWrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
