import React from "react";
import styled from "styled-components";

export const Button = styled.button`
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 4px 8px;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #0456f5;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  color: white;
  min-width: 100px;
  width: fit-content;
  max-height: 42px;
  &:focus,
  &:active {
    outline: none;
  }

  ${({ secondary }) =>
    secondary &&
    `
    background-color: #E9E7F9;
    color: #1E2168;
  `}
`;

export default Button;
